<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <section class="subHeader-content">
      <blueHeader :itemsHeader="itemsHeader" />
  
      <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
      
    </section>
  </template>
   
  <script>
  import { Role } from "@/router-external/role.js";
  import BlueHeader from '../../../../components/BlueHeader.vue';
  
  export default {
    components: { BlueHeader },
    name: 'Requests',
    data: () => ({
      itemsHeader: [
        {
          name: 'Solicitud entrega',
          link: { name: 'external.login.modules.affiliates.requests.entrega' },
          rol: Role.Afiliados_Solicitud_Entrega,
        },
      ]
    }),
  }
  </script>
   
  <style scoped>
  .subHeader-content {
    width: 100%;
  }
  </style>